<template>
  <div class="task-view">
    <v-alert
      v-if="isNeedSign && pointData.canEdit"
      class="mb-2 mb-md-6 mt-4 mt-md-0 text-body-2"
      text="Для завершения необходимо подписать отправленные данные, а так же все файлы, требующие подписания."
      type="info"
      variant="tonal"
    />
    <div :key="getGroupValues(null, 0)?.length">
      <div v-for="(field, index) in getGroupValues(null, 0)" :key="index">
        <div class="border-bottom-line task-value align-center flex-column flex-md-row">
          <div class="value-title">
            <template v-if="getIsShowNameField(field, index, getGroupValues(null, 0))">{{
              getFieldName(field.fieldCode)
            }}</template>
          </div>
          <div class="d-flex align-center justify-start value">
            <v-icon v-if="field.isAgree" color="primary">mdi-check-bold</v-icon>
            <template v-else>{{ getFieldValue(field) || '-' }}</template>
          </div>
        </div>
        <div v-if="getDescription(field.fieldCode)" class="text-caption text-grey-darken-1">
          <div class="mt-2 pa-4" v-html="getDescription(field.fieldCode)"></div>
        </div>
      </div>
      <v-expansion-panels
        v-for="group in uniqueGroups"
        :key="group.code1C"
        :model-value="0"
        :class="['accordion', group.multiple && isLastGroup(group) && !group.readOnly && 'mb-0']"
      >
        <v-expansion-panel expand>
          <v-expansion-panel-title>
            <template #actions="{ expanded }">
              <v-icon :icon="expanded ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
            </template>
            {{ group.name }}
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <template v-if="group.multiple">
              <!-- Отображение данных в виде таблицы -->
              <template v-if="!getTableRows(group.code1C)?.length">
                <div class="text-grey text-caption text-center">Нет данных</div>
              </template>
              <v-table>
                <thead>
                  <tr>
                    <th v-for="(field, index) in getTableHeaders(group.code1C)" :key="index">
                      {{ field }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, rowIndex) in getTableRows(group.code1C)" :key="rowIndex">
                    <td v-for="(value, colIndex) in row" :key="colIndex">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <template v-if="!getTableRows(group.code1C)?.length">
                <div class="text-grey text-caption text-center">Нет данных</div>
              </template>
            </template>
            <template v-else>
              <template v-if="getGroupValues(group.code1C, group.index)?.length">
                <div
                  v-for="(field, index) in getGroupValues(group.code1C, group.index)"
                  :key="field.id"
                >
                  <div
                    :class="['task-value align-start flex-column flex-md-row border-bottom-line']"
                  >
                    <div class="value-title">
                      <template
                        v-if="
                          getIsShowNameField(
                            field,
                            index,
                            getGroupValues(group.code1C, group.index),
                          )
                        "
                      >
                        {{ getFieldName(field.fieldCode) }}</template
                      >
                    </div>
                    <div class="d-flex align-center justify-start value">
                      <v-icon v-if="field.isAgree" color="primary" size="xs">mdi-check-bold</v-icon>
                      <template v-else>{{ getFieldValue(field) || '-' }}</template>
                    </div>
                  </div>
                  <div
                    v-if="getDescription(field.fieldCode)"
                    class="text-caption text-grey-darken-1"
                  >
                    <div class="mt-2 pa-4" v-html="getDescription(field.fieldCode)"></div>
                  </div>
                </div>
              </template>
              <div
                v-else-if="!getFieldsByGroup(group.code1C)?.length"
                class="text-grey text-caption text-center"
              >
                Нет данных
              </div>
              <template v-if="getFieldsByGroup(group.code1C)?.length">
                <div v-for="fieldAttach in getFieldsByGroup(group.code1C)" :key="fieldAttach.id">
                  <div class="border-bottom-line task-value align-center flex-column flex-md-row">
                    <div class="value-title">
                      {{ fieldAttach.name }}
                    </div>
                    <div class="value d-flex flex-column">
                      <div
                        v-for="file in getFilesByGroup(group.code1C, group.index, fieldAttach)"
                        :key="file.id"
                      >
                        <div
                          class="d-flex justify-space-between align-center file-value-wrapper"
                          @click="() => downloadFile(file.id, file?.originalFileName)"
                        >
                          <div class="d-flex cursor-pointer align-center">
                            <v-icon size="20" color="primary">{{
                              getFileIcon(file?.fileName)
                            }}</v-icon>
                            <v-tooltip text="Скачать" location="bottom">
                              <template #activator="{ props }">
                                <span v-bind="props" class="ml-2 font-weight-medium text-caption">{{
                                  file?.fileName
                                }}</span>
                              </template>
                            </v-tooltip>
                          </div>
                          <div>
                            <template v-if="file.signValid">
                              <v-tooltip text="Документ подписан" location="bottom">
                                <template #activator="{ props }">
                                  <v-icon size="20" v-bind="props" class="ml-2" color="green"
                                    >mdi-check</v-icon
                                  >
                                </template>
                              </v-tooltip>
                            </template>
                            <template v-else-if="file.signable">
                              <v-tooltip text="Требуется подпись" location="bottom">
                                <template #activator="{ props }">
                                  <v-icon size="16" class="ml-2" v-bind="props" color="grey"
                                    >mdi-pen</v-icon
                                  >
                                </template>
                              </v-tooltip>
                            </template>
                          </div>
                        </div>
                        <template v-if="getFileDescription(file.attachCode)">
                          <div
                            class="text-caption text-grey-darken-1 mt-2 py-2"
                            v-html="getFileDescription(file.attachCode)"
                          />
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
        <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
      </v-row>
      <div v-else-if="Object.keys(groupNames)?.length" :class="[point.signable ? 'mb-6' : 'mb-12']">
        <div v-if="files?.length" class="font-weight-bold my-6 mt-8 files-title">
          Скачать пакет документов:
        </div>
        <div v-for="(group, groupIndex) in Object.keys(groupNames)" :key="group">
          <div
            v-if="Object.values(groupNames)[groupIndex]"
            class="mt-4 font-weight-bold text-subtitle-2 text-grey"
          >
            {{ Object.values(groupNames)[groupIndex] }}
          </div>
          <div
            v-for="(fields, fieldsIndex) in Object.values(
              getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'),
            )"
            :key="
              Object.keys(getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'))[
                fieldsIndex
              ]
            "
            class="border-bottom-line task-value align-center flex-column flex-md-row"
          >
            <div class="value-title">
              {{
                getAttachFieldName(
                  Object.keys(getFieldsListByField(getFieldsByGroupArr(group), 'attachCode'))[
                    fieldsIndex
                  ],
                )
              }}
            </div>
            <div class="value d-flex flex-column">
              <div v-for="file in fields" :key="`file-${file.id}`">
                <div
                  class="d-flex justify-space-between align-center file-value-wrapper"
                  @click="() => downloadFile(file.id, file?.originalFileName)"
                >
                  <div class="d-flex cursor-pointer align-center">
                    <v-icon size="20" color="primary">{{ getFileIcon(file?.fileName) }}</v-icon>
                    <v-tooltip text="Скачать" location="bottom">
                      <template #activator="{ props }">
                        <span v-bind="props" class="ml-2 font-weight-medium text-caption">{{
                          file?.fileName
                        }}</span>
                      </template>
                    </v-tooltip>
                  </div>
                  <div>
                    <template v-if="file.signValid">
                      <v-tooltip text="Документ подписан" location="bottom">
                        <template #activator="{ props }">
                          <v-icon size="20" v-bind="props" class="ml-2" color="green"
                            >mdi-check</v-icon
                          >
                        </template>
                      </v-tooltip>
                    </template>
                    <template v-else-if="file.signable">
                      <v-tooltip text="Требуется подпись" location="bottom">
                        <template #activator="{ props }">
                          <v-icon size="16" class="ml-2" v-bind="props" color="grey"
                            >mdi-pen</v-icon
                          >
                        </template>
                      </v-tooltip>
                    </template>
                  </div>
                </div>
                <template v-if="getFileDescription(file.attachCode)">
                  <div
                    class="text-caption text-grey-darken-1 mt-2 py-2"
                    v-html="getFileDescription(file.attachCode)"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="action-panel">
        <div>
          <v-btn
            v-if="pointData.canHold"
            color="red"
            @click="isShowWithdrawAppDialog = !isShowWithdrawAppDialog"
            >{{
              pointData?.status?.code1C === POINTS_STATUSES.SIGNATURE_REQUIRED
                ? 'Вернуться к редактированию'
                : 'Отозвать заявку'
            }}</v-btn
          >
        </div>
        <div class="d-flex justify-end align-center">
          <v-btn :loading="isLoadPdf" color="black" @click="downloadPdf">Скачать zip</v-btn>
          <v-btn
            :disabled="isGetPdfLoading"
            :loading="isGetPdfLoading"
            color="black"
            @click="getFileForSign(true)"
          >
            <v-row class="pa-md-0 pa-0 ma-0 align-center" cols="12" md="8" lg="8">
              <v-icon color="white" class="d-inline">mdi-file-pdf-box</v-icon>
              <span class="ml-2 font-weight-medium text-caption font-weight-bold"
                >Выгрузка в PDF</span
              >
            </v-row>
          </v-btn>
          <v-btn v-if="isNeedSign && pointData.canSign" color="primary" @click="showSignDialog">
            Подписать и отправить
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  <v-dialog v-model="isShowWithdrawAppDialog" max-width="400">
    <v-card class="w-100">
      <v-card-text>
        <div class="text-body-2">
          Данные, поданные в рамках "<span v-html="pointData.name" />" будут отозваны. Вы согласны?
          После этого вы сможете отредактировать "<span v-html="pointData.name" />" и сформировать
          <template v-if="pointData.closedate"
            >повторно, до {{ getFormatDate(pointData.closedate) }}.</template
          >
          <template v-else>повторно.</template>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-end align-center pa-4">
        <v-btn variant="text" class="flex-grow-1" @click="isShowWithdrawAppDialog = false">
          Отмена
        </v-btn>
        <v-btn color="primary" variant="elevated" class="flex-grow-1" @click="onWithdrawApp"
          >Отозвать заявку</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isShowSignDialog" max-width="400">
    <div v-if="isNeedSign && pointData.canSign" class="bg-white sign-dialog w-100">
      <div class="dialog-title">Выберите сертификат для подписи</div>
      <div class="dialog-content">
        <v-select
          v-model="currentCertificate"
          :items="certificates"
          label="Сертификат"
          item-title="text"
          class="mb-4 mb-md-0"
          item-value="value"
          variant="outlined"
          hide-details="auto"
          return-object
        />
        <pre v-if="errorMsg" class="mt-2 text-red white-space-word-break">{{ errorMsg }}</pre>
      </div>
      <div class="d-flex align-center dialog-actions">
        <v-btn class="default-button" @click="() => (isShowSignDialog = false)">Отменить</v-btn>
        <v-btn
          v-if="isNeedSign"
          :disabled="!currentCertificate || isLoadingSignFile"
          color="primary"
          :loading="isLoadingSignFile"
          @click="handleToggleSignAllFilesDialog"
        >
          Подписать и отправить
        </v-btn>
      </div>
    </div>
  </v-dialog>

  <v-dialog v-model="isShowDialogSignFiles">
    <v-card max-width="800" class="w-100">
      <v-card-title class="font-weight-bold">
        <v-row class="pa-0 ma-0 justify-space-between">
          <span>Подписание {{ pointData?.name ? `«${pointData?.name}»` : '' }}</span>
          <v-btn
            prepend-icon="mdi-close"
            variant="flat"
            class="close-button"
            @click="handleCloseModal"
          />
        </v-row>
      </v-card-title>
      <v-card-text :key="signHistory" class="mb-4">
        <v-row
          v-for="(item, index) in signHistory"
          :key="`history-${item.sign}-${index}`"
          class="pa-0 ma-0 align-center"
        >
          <span :class="[item.errorText && 'text-red']">{{ item.text }}</span>
          <div class="mx-2 d-flex align-center justify-center">
            <v-icon v-if="item.sign" color="green">mdi-check</v-icon>
            <v-icon v-else-if="item.errorText" size="xs" color="red"
              >mdi-alert-circle-outline</v-icon
            >
            <div v-else-if="item.isError"></div>
            <v-icon v-else>
              <v-progress-circular indeterminate color="primary" :size="15" :width="2" />
            </v-icon>
          </div>
          <div v-if="item.errorText" class="text-red">({{ item.errorText }})</div>
        </v-row>
      </v-card-text>
      <v-card-text v-if="signError" class="mt-0 pt-0 text-red pb-6">
        <v-alert :text="signError" type="error" color="red" variant="tonal" />
      </v-card-text>
      <v-card-actions v-if="!isLoadSign" class="justify-end">
        <v-btn color="primary" variant="flat" @click="handleToggleSignAllFilesDialog">Готово</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import moment from 'moment'
import { api } from '@/api/Api'
import { downloadFileWithLinkData, getFileIcon, getFormatDate } from '@/helpers'
import { useRoute } from 'vue-router'
import { getUserCertificates, createHash, createDetachedSignature } from 'crypto-pro'
import { useToast } from '@/composables/useToast'
import { emit as baseEmit } from '@/composables/form/usePropsForm'
import { POINTS_STATUSES } from '../../constants/points'

const route = useRoute()
const { toast } = useToast()

const SIGN_CODE = '16534e9c-2dea-41a4-9df0-26ebe750f6ae'

const emit = defineEmits(baseEmit)

const props = defineProps({
  pointData: {
    type: Object,
    default: null,
  },
})

const groups = computed(() => props.pointData?.templateGroups)
const point = computed(() => props.pointData)
const isNeedSign = computed(
  () =>
    props.pointData?.status?.code1C === SIGN_CODE && point.value.signable && isShowSignForm.value,
)

const isLoadSign = computed(() => !!signHistory.value?.find((el) => !el.errorText && !el.sign))
const isGetPdfLoading = ref(false)
const isShowSignDialog = ref(false)
const currentCertificate = ref(null)
const errorMsg = ref('')
const signFile = ref()
const isShowSignForm = ref(true)
const signFileBase64 = ref()
const isLoadingSignFile = ref(false)
const certificates = ref([])
const formatFields = ref([])
const signHistory = ref([])
const groupsFields = ref(null)
const files = ref([])
const groupsNames = ref({})
const fieldsNames = ref(null)
const attachFieldsNames = ref(null)
const isLoading = ref(false)
const isSignForm = ref(false)
const isLoadPdf = ref(false)
const isShowDialogSignFiles = ref(false)
const filesForSign = ref([])
const signError = ref('')

const groupNames = ref({})
const fieldNames = ref({})
const isShowWithdrawAppDialog = ref(false)

const getFilesByGroup = (code1C, index, field) => {
  return files.value?.filter(
    (file) =>
      file?.groupCode === code1C && file?.groupIndex === index && file?.attachCode === field.code1C,
  )
}

const getDescription = (fieldCode) => {
  const field = props.pointData?.templateFields?.find((el) => el.code1C === fieldCode)
  if (!field || !field?.printDescription) {
    return ''
  }

  if (field.printDescription) {
    return field.description
  }
}

const showSignDialog = () => {
  isShowSignDialog.value = true
}

const getFieldsByGroup = (code1C) => {
  return props.pointData?.templateAttachments?.filter((el) => el.groupCode === code1C)
}

const onWithdrawApp = async () => {
  try {
    await api
      .projects()
      .withdrawApp(point.value?.id)
      .then(() => {
        isShowWithdrawAppDialog.value = false
        location.reload()
      })
  } catch (err) {
    console.log(err)
    if (err?.response?.data?.message) {
      toast.error(err?.response?.data?.message)
    }
  }
}

const handleToggleSignAllFilesDialog = () => {
  const newValue = !isShowDialogSignFiles.value
  isShowDialogSignFiles.value = newValue

  if (!newValue) {
    signHistory.value = []
    errorMsg.value = ''
    getSignedFiles(true)
    location.reload()
    return
  }
  nextTick(() => {
    signFilesList()
  })
}

const uniqueGroups = computed(() => {
  const unique = []
  const seen = new Set()
  Object.values(groupsFields.value || {})?.forEach((group) => {
    if (!seen.has(group.code1C)) {
      seen.add(group.code1C)
      unique.push(group)
    }
  })
  return unique || []
})

const getTableHeaders = (groupCode) => {
  const fields = props.pointData?.templateFields?.filter((field) => field.groupCode === groupCode)
  return fields.map((field) => field.name)
}

const getTableRows = (groupCode) => {
  const fields = props.pointData?.templateFields?.filter((field) => field.groupCode === groupCode)
  const values = props.pointData.values.filter((value) =>
    fields.some((field) => field.code1C === value.fieldCode),
  )

  // Группируем значения по groupIndex
  const rows = []
  values.forEach((value) => {
    const rowIndex = value.groupIndex || 0
    if (!rows[rowIndex]) {
      rows[rowIndex] = {}
    }
    rows[rowIndex][value.fieldCode] = getFieldValue(value)
  })

  return rows.map((row) => fields.map((field) => row[field.code1C] || '-'))
}

const signFilesList = async () => {
  if (!filesForSign.value.length) {
    return createSignatureClick()
  }
  await startSign(0)
}

const startSign = async (i) => {
  const index = i || 0
  const file = filesForSign.value[index]

  signHistory.value = [
    ...signHistory.value,
    {
      text: `Подписываем ${index + 1} файл из ${filesForSign.value.length}`,
      sign: false,
      isError: false,
    },
  ]
  await signFileAction(file, index).finally(() => {
    if (index + 1 < filesForSign.value.length) {
      startSign(index + 1)
    }
  })
}

const sendSignFile = async (sign, id, index) => {
  api
    .files()
    .saveSignFile(id, sign)
    .then(() => {
      signHistory.value[index].sign = true
      checkValidSignFiles()
    })
    .catch((err) => {
      signHistory.value[index].isError = true
      signHistory.value[index].errorText = err?.response?.data?.message || err?.response?.data
    })
}

async function hashBase64Data(base64Data) {
  try {
    const binaryString = atob(base64Data)
    const dataBytes = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      dataBytes[i] = binaryString.charCodeAt(i)
    }

    const hashOptions = {
      algorithm: 'GOST R 34.11-2012',
      hashSize: 256,
    }
    const dataHash = await createHash(dataBytes, hashOptions)

    console.log('Хеш данных из Base64 (ГОСТ Р 34.11-2012): ', dataHash)
    return dataHash
  } catch (error) {
    console.error('Ошибка при хешировании данных из Base64: ', error)
    throw error
  }
}

const checkValidSignFiles = async () => {
  const hasError = signHistory.value?.find((el) => !el.sign)
  if (!hasError) {
    createSignatureClick()
  }
}

const sendFileForFile = async (base64, file, index) => {
  const thumbprint = currentCertificate.value?.value
  try {
    // eslint-disable-next-line no-unused-vars
    let hashValue = ''
    const signOptions = {
      detached: true,
      includeWholeChain: true,
    }
    await hashBase64Data(base64).then((hash) => {
      hashValue = hash
    })
    const signature = await createDetachedSignature(thumbprint, hashValue, signOptions)
    await sendSignFile(signature, file.id, index).then(() => {
      errorMsg.value = ''
    })
  } catch (e) {
    signHistory.value[index].errorText = e.message
    errorMsg.value = `${e.message}`
  }
}

const signFileAction = async (file, index) => {
  await api
    .files()
    .getBase64File(file.id)
    .then((data) => {
      sendFileForFile(data?.content, file, index)
    })
    .catch((err) => {
      console.log(err)
      signHistory.value = [
        ...signHistory.value,
        {
          text: `- Ошибка при подписании файла ${index + 1}`,
          sign: false,
          isError: true,
        },
      ]
    })
}
const getFieldsListByField = (array, key) => {
  return array.reduce((accumulator, object) => {
    ;(accumulator[object[key]] ??= []).push(object)
    return accumulator
  }, {})
}

const getFieldsByGroupArr = (groupCode) => {
  return files.value?.filter((field) => field.groupCode === groupCode)
}

const getFileDescription = (fieldCode) => {
  const field = props.pointData?.templateAttachments?.find((el) => el.code1C === fieldCode)
  if (!field || !field?.printDescription) {
    return ''
  }

  if (field.printDescription) {
    return field.description
  }
}

function getIsShowNameField(field, index, group) {
  return (
    !index ||
    !group[index - 1] ||
    getFieldName(group[index - 1].fieldCode) !== getFieldName(field.fieldCode)
  )
}

function getGroupValues(id, groupIndex) {
  if (!id) {
    return formatFields.value.filter((field) => !field.groupCode)?.sort((a, b) => a.sort - b.sort)
  } else
    return formatFields.value
      .filter((el) => el.groupCode === id && el.groupIndex === groupIndex)
      ?.sort((a, b) => (a.code1C > b.code1C ? -1 : 1))
      .sort((a, b) => a.sort - b.sort)
}

function getFieldName(id) {
  return fieldsNames.value && id && id in fieldsNames.value ? fieldsNames.value[id] : ''
}

function getAttachFieldName(id) {
  return id in attachFieldsNames.value ? attachFieldsNames.value[id] : ''
}

async function getLinkZip(id, name) {
  try {
    await api
      .files()
      .getLinkProject(id)
      .then((data) => {
        if (data?.link) {
          downloadFileWithLinkData(data, name)
        }
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoadPdf.value = false
  }
}

async function downloadZip(id, name) {
  try {
    await getLinkZip(id, name)
    // await api
    //   .files()
    //   .downloadZipProject(id)
    //   .then((data) => {
    //     downloadBlob(data, `${name}.zip`)
    //   })
  } catch (err) {
    console.log(err)
  } finally {
    isLoadPdf.value = false
  }
}

async function downloadFile(id, name) {
  try {
    await api
      .files()
      .getLinkFile(id)
      .then((data) => {
        downloadFileWithLinkData(data, name)
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoadPdf.value = false
    isGetPdfLoading.value = false
  }
}

function getFieldValue(field) {
  if (!field.value) {
    return '-'
  }

  switch (field?.fieldType?.dataType) {
    case 'date': {
      const isValidDate = moment(field.value, true).isValid()
      return isValidDate ? moment(field.value).format('DD.MM.YYYY') : field.value
    }
    case 'datetime-local': {
      const isValidDate = moment(field.value, true).isValid()
      return isValidDate ? moment(field.value).format('DD.MM.YYYY HH:mm:ss') : field.value
    }
    default:
      return field.value || ''
  }
}

async function getSignedFiles(isUpdate) {
  isLoading.value = true
  try {
    await api
      .files()
      .getSignedFiles({
        [`filter[pointId]`]: route.params.id,
      })
      .then((data) => {
        files.value = isUpdate ? [...data] : [...files.value, ...data]
        filesForSign.value = isUpdate
          ? data?.filter((el) => !!el.signable && !el.signValid)
          : [...filesForSign.value, ...data?.filter((el) => !!el.signable && !el.signValid)]
        files.value
          ?.sort((a, b) => a.sort - b.sort)
          ?.forEach((file) => {
            groupNames.value[file.groupCode] = ''
            fieldNames.value[file.attachCode] = ''
          })
        props.pointData?.templateGroups?.forEach((group) => {
          if (group.code1C in groupNames.value) {
            groupNames.value[group.code1C] = group.name
          }
        })
        props.pointData?.templateAttachments?.forEach((field) => {
          if (field.code1C in fieldNames.value) {
            fieldNames.value[field.code1C] = field.name
          }
        })
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

function isLastGroup(group) {
  let allFieldsByCode1C = props.pointData.templateFields?.filter(
    (field) => field.code1C === group.code1C,
  )
  const lastElement = allFieldsByCode1C[allFieldsByCode1C.length - 1]
  return lastElement?.groupIndex === group.groupIndex && group.code1C === lastElement?.code1C
}

function getForm() {
  let fieldsGroup = {}
  props.pointData?.templateFields?.forEach((field) => {
    fieldsGroup[field.code1C] = field.groupCode
    fieldsNames.value = {
      ...fieldsNames.value,
      [field.code1C]: field.name,
    }
  })
  props.pointData?.templateAttachments?.forEach((field) => {
    attachFieldsNames.value = {
      ...attachFieldsNames.value,
      [field.code1C]: field.name,
    }
  })
  const fields = props.pointData.templateFields
  formatFields.value = props.pointData.values.map((field) => {
    const fieldFind = fields?.find((el) => el.code1C == field.fieldCode)
    const sort = fieldFind?.sort || 0
    const isAgree = fieldFind?.fieldType?.id === 16 && field.value === 'Да'
    return {
      ...field,
      sort: sort,
      isAgree,
      groupCode: fieldsGroup[field.fieldCode] || null,
    }
  })
  groups.value.forEach((group) => {
    groupsFields.value = {
      ...groupsFields.value,
      [`${group.code1C}-0`]: {
        ...group,
        id: `${group.code1C}-0`,
        index: 0,
      },
    }
  })
  formatFields.value?.forEach((field) => {
    if (field.groupIndex && !(`${field.groupCode}-${field.groupIndex}` in groupsFields.value)) {
      const group = groups?.value.find((groupEl) => groupEl.code1C === field.groupCode)
      if (!group) {
        return
      }
      groupsFields.value = {
        ...groupsFields.value,
        [`${field.groupCode}-${field.groupIndex}`]: {
          ...group,
          index: field.groupIndex,
        },
      }
    }
  })
  groups.value?.forEach((group) => {
    groupsNames.value = { ...groupsNames.value, [group.code1C]: group.name }
  })
}

const createSignatureClick = async () => {
  const index = signHistory.value.length + 1
  signHistory.value = [
    ...signHistory.value,
    {
      text: `Подписываем проектную точку`,
      sign: false,
      isError: false,
    },
  ]
  isLoadingSignFile.value = true
  await getFileForSign()
    .then(() => {
      createSignatureStartProcess()
      signHistory.value[index].sign = true
      signError.value = ''
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        signError.value = err?.response?.data?.message
        toast.error(err?.response?.data?.message)
      }
      signHistory.value[signHistory.value.length - 1].isError = true
      isLoadingSignFile.value = false
    })
}

const createSignatureStartProcess = async () => {
  const thumbprint = currentCertificate.value?.value
  try {
    let hashValue = ''
    const signOptions = {
      detached: true,
      includeWholeChain: true,
    }
    await hashBase64Data(signFileBase64.value).then((hash) => {
      hashValue = hash
    })
    const signature = await createDetachedSignature(thumbprint, hashValue, signOptions)
    if (signature) {
      await signFormFile(signature)
    }
    errorMsg.value = ''
  } catch (e) {
    errorMsg.value = `${e.message}`
  } finally {
    isLoadingSignFile.value = false
  }
}

async function signFormFile(signature) {
  try {
    const params = new FormData()
    params.append('sign', signature)
    params.append('fileBase64', signFileBase64.value)
    await api
      .projects()
      .signFile(point.value.id, params)
      .then(() => {
        toast.success('Анкетные данные успешно подписаны')
        isSignForm.value = true
        isShowSignForm.value = false
        signError.value = ''
      })
  } catch (err) {
    if (err?.response?.data?.message) {
      signError.value = err?.response?.data?.message
      toast.error(err?.response?.data?.message)
    }
    console.log(err)
  }
}

async function getFileForSign(isDownload) {
  isGetPdfLoading.value = true
  try {
    if (point.value.printFileId) {
      return downloadFile(point.value.printFileId, point.value?.name)
    }
    const file = await api.projects().getFileForSign(point.value.id)
    const base64File = await api.projects().getFileForSignString(point.value.id, true)
    const blobFile = await api.projects().getFileForSign(point.value.id)
    signFile.value = file
    signFileBase64.value = base64File

    if (isDownload) {
      downloadBlob(blobFile, `${point.value.name}.pdf`)
    }
  } catch (err) {
    console.log(err)
  } finally {
    isGetPdfLoading.value = false
    isLoadPdf.value = false
  }
}

function downloadBlob(blob, name = 'file.txt') {
  console.log(blob, '1111')
  if (window.navigator && window.navigator.msSaveOrOpenBlob)
    return window.navigator.msSaveOrOpenBlob(blob)
  const data = window.URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = data
  link.download = name
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  setTimeout(() => {
    window.URL.revokeObjectURL(data)
    link.remove()
  }, 100)
}

function downloadPdf() {
  isLoadPdf.value = true
  downloadZip(point.value?.id, point.value?.name)
}

const handleCloseModal = () => {
  isShowDialogSignFiles.value = !isShowDialogSignFiles.value
  signError.value = ''
  signHistory.value = []
}

async function waitForPlugin() {
  try {
    await cadesplugin
    console.log('Плагин успешно загружен')
  } catch (error) {
    console.error('Ошибка загрузки плагина:', error)
  }
}

const getCerts = async () => {
  //const errorMsg = document.getElementById('errorMessage')
  try {
    await waitForPlugin()
    const certificateList = await getUserCertificates()
    certificateList.forEach(({ name, thumbprint, validTo }) => {
      certificates.value = [
        ...certificates.value,
        {
          text: `${name} (действителен до: ${moment(validTo).format('DD.MM.YYYY HH:mm')})`,
          value: thumbprint,
        },
      ]
    })
  } catch (e) {
    errorMsg.value = `\n${e.message}`
  }
}

const getData = (isUpdate) => {
  getSignedFiles(isUpdate)
  getForm()

  if (isNeedSign.value && point.value?.canSign) {
    getCerts()
  }
}

watch(isSignForm, () => {
  if (isSignForm.value) {
    emit('update')
    getData(true)
  }
})

onMounted(() => {
  getData()
})
</script>
<style lang="scss" scoped>
.accordion {
  margin-top: 24px;
}

.btn-submit {
  min-height: 48px;
}

.form-footer {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  bottom: 0;
  left: 0;
}

.file-wrapper {
  transition: all 0.3s;
  border-radius: 8px;

  &:hover {
    background: #f0f0f06e;
  }
}

.action-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 288px);
  z-index: 9;
  padding: 14px 24px;
  gap: 8px;
  background: white;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--menu-color);

  > div {
    gap: 8px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 568px) {
    flex-direction: column;

    > div {
      dislpay: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;

  .v-btn__prepend {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-end: 0;
  }
}

.task-view {
  padding: 24px;
  flex: 1;

  @media (max-width: 567px) {
    padding: 24px 24px 196px 24px;
  }
}

.task-value {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  padding: 16px 0;

  @media (max-width: 765px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .value-title {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-caption);
  }

  .value {
    font-size: 16px;
    line-height: 24px;
    gap: 8px;
  }
}

.files-title {
  font-size: 16px;
  line-height: 20px;
}

.dialog-actions {
  padding: 24px;
  gap: 8px;
  display: flex;
  align-items: center;

  button {
    &:first-child {
      width: 145px;
    }
  }
}
</style>
