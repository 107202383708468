<template>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else-if="project" class="tabs-container">
    <div class="program-head bg-white">
      <v-btn
        color="primary"
        density="comfortable"
        icon="mdi-arrow-left"
        variant="text"
        class="user ma-0 pa-0"
        @click="handleGoBackClick"
      />
      <h2 class="title" v-html="project?.name" />
    </div>
    <v-tabs v-model="tab" color="primary" class="tabs">
      <v-tab :key="0"
        >Задачи
        <div v-if="countPoints" class="counter tab-counter">{{ countPoints }}</div></v-tab
      >
      <v-tab :key="1">Сведения о проекте</v-tab>
      <v-tab :key="2">Сведения о конкурсе</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="tabs-wrapper">
      <v-tabs-it v-if="tab === 1">
        <div v-if="project" class="program-content">
          <div class="content">
            <div class="value-wrapper">
              <div class="label">Описание проекта:</div>
              <div v-if="project?.description" class="value" v-html="project?.description" />
            </div>
            <div>
              <div v-if="files?.length" class="font-weight-bold my-2 files-title">
                Файлы проекта:
              </div>
              <div v-for="file in files" :key="`file-${file.id}`" class="mb-2">
                <div
                  class="d-flex justify-space-between align-center file-value-wrapper"
                  @click="() => downloadFile(file)"
                >
                  <div class="d-flex cursor-pointer align-start">
                    <v-icon class="mt-1" size="20" color="primary">{{
                      getFileIcon(file?.name)
                    }}</v-icon>
                    <v-tooltip text="Скачать" location="bottom">
                      <template #activator="{ props }">
                        <div class="ml-2" v-bind="props">
                          <span class="font-weight-medium text-caption">{{ file?.name }}</span>
                          <div v-if="file.description" class="text-caption text-grey">
                            {{ file.description }}
                          </div>
                        </div>
                      </template>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sidebar">
            <div class="d-flex justify-space-between align-center task-row">
              <div class="chips">
                <div v-if="project.status?.name" class="chip chip_black">
                  {{ project.status?.name }}
                </div>
              </div>
            </div>
            <div v-if="project.id" class="value-wrapper">
              <div class="label">ID проекта:</div>
              <div class="value" v-html="project.id || '-'"></div>
            </div>
            <div v-if="project.counterparty" class="value-wrapper">
              <div class="label">Наименование контрагента:</div>
              <div class="value" v-html="project.counterparty || '-'"></div>
            </div>
            <div v-if="project.inn" class="value-wrapper">
              <div class="label">ИНН:</div>
              <div class="value" v-html="project.inn || '-'"></div>
            </div>
            <div v-if="project.contractNo" class="value-wrapper">
              <div class="label">№ договора:</div>
              <div class="value" v-html="project.contractNo || '-'"></div>
            </div>
            <!--            <div v-if="project.projectPPP" class="value-wrapper">-->
            <!--              <div class="label">Код ППП:</div>-->
            <!--              <div class="value" v-html="project.projectPPP || '-'"></div>-->
            <!--            </div>-->
            <!--            <div v-if="project.briefcaseCode" class="value-wrapper">-->
            <!--              <div class="label">Портфель:</div>-->
            <!--              <div class="value" v-html="briefcase || '-'"></div>-->
            <!--            </div>-->
            <!--            <div v-if="project.projectTypeCode" class="value-wrapper">-->
            <!--              <div class="label">Тип проекта:</div>-->
            <!--              <div class="value" v-html="project.projectTypeCode || '-'"></div>-->
            <!--            </div>-->
            <!--            <div v-if="project.programmCode" class="value-wrapper">-->
            <!--              <div class="label">Программа:</div>-->
            <!--              <div class="value" v-html="project.programmCode || '-'"></div>-->
            <!--            </div>-->
          </div>
        </div>
      </v-tabs-it>
      <v-tabs-item v-if="tab === 2">
        <div v-if="program" class="program-content">
          <div class="content">
            <div v-if="program?.description" class="value" v-html="program?.description" />
            <div v-if="program?.importantInfo" class="mt-2 importantInfo">
              <div class="value" v-html="program?.importantInfo" />
            </div>
            <div v-if="program.fullName" class="value-wrapper">
              <div class="label">Полное наименование конкурса:</div>
              <div class="value" v-html="program.fullName" />
            </div>
            <div v-if="program.participantsRequirements" class="value-wrapper">
              <div class="label">Требования к участникам конкурсного отбора:</div>
              <div class="value" v-html="program.participantsRequirements || '-'" />
            </div>
            <div v-if="program.implementationRequirements" class="value-wrapper">
              <div class="label">Требования к реализации проекта участниками отбора:</div>
              <div class="value" v-html="program.implementationRequirements || '-'" />
            </div>
            <div v-if="program.fundsRequirements" class="value-wrapper">
              <div class="label">Требования к расходованию бюджетных средств:</div>
              <div class="value" v-html="program.fundsRequirements || '-'" />
            </div>
            <div v-if="program.cofinancingRequirements" class="value-wrapper">
              <div class="label">Требования к объему софинансирования проекта:</div>
              <div class="value" v-html="program.cofinancingRequirements || '-'" />
            </div>
          </div>
          <div class="sidebar">
            <div class="d-flex justify-space-between align-center task-row">
              <div class="chips">
                <div v-if="program.status" class="chip chip_black">
                  {{ program.status }}
                </div>
                <div v-if="program.hasUserActivePoints" class="chip chip_border">Участвую</div>
              </div>
              <div v-if="program.hasActivePoints" class="task">Есть задачи!</div>
            </div>
            <div class="dates">
              <div v-if="program.dateFrom && program.dateTo">
                <div class="label">Проведение конкурса:</div>
                <div class="value">
                  {{ getFormatDateDefaultShort(program.dateFrom) }} -
                  {{ getFormatDateDefaultShort(program.dateTo) }}
                </div>
              </div>
              <div v-if="program.resultDateFrom && program.resultDateTo">
                <div class="label">Подведение итогов:</div>
                <div class="value">
                  {{ getFormatDateDefaultShort(program.resultDateFrom) }} -
                  {{ getFormatDateDefaultShort(program.resultDateTo) }}
                </div>
              </div>
            </div>
            <div v-if="program.supportLimit" class="value-wrapper">
              <div class="label">
                Предельный объем поддержки реализации проекта за счет средств субсидии:
              </div>
              <div class="value" v-html="program.supportLimit || '-'"></div>
            </div>
            <div v-if="program.dateFillFrom" class="value-wrapper">
              <div class="label">Дата начала заполнения заявок:</div>
              <div class="value" v-html="getFormatDateTime(program.dateFillFrom) || '-'" />
            </div>
            <div v-if="program.dateSubmitFrom" class="value-wrapper">
              <div class="label">Дата начала приема заявок:</div>
              <div class="value" v-html="getFormatDateTime(program.dateSubmitFrom) || '-'" />
            </div>
            <div v-if="program.dateTo" class="value-wrapper">
              <div class="label">Дата окончания приема заявок:</div>
              <div class="value" v-html="getFormatDateTime(program.dateTo) || '-'" />
            </div>
            <div v-if="program.files?.length" class="files">
              <div class="subtitle-1">
                {{ 'Дополнительные материалы' }}
              </div>
              <template v-if="!program?.preRegistration">
                <div v-for="item in program.files" :key="`${item.id}`" class="file">
                  <v-icon color="grey">{{ getFileIcon(item?.name) }}</v-icon>
                  <div>
                    <div v-if="item?.name" class="name">{{ item?.name }}</div>
                    <div v-if="item?.description" class="description">{{ item?.description }}</div>
                    <div class="download-button" @click="() => downloadFile(item)">
                      Скачать
                      <v-icon color="primary">mdi-arrow-down</v-icon>
                    </div>
                  </div>
                </div>
              </template>
              <div v-else class="mb-6 mt-2">
                <v-alert
                  text="Материалы будут доступны после предварительной регистрации"
                  type="info"
                  variant="tonal"
                />
              </div>
            </div>
          </div>
        </div>
      </v-tabs-item>
      <v-tabs-item v-if="tab === 0">
        <div class="task-wrapper">
          <ProgramTasksList
            :template-code="project?.templateCode"
            :count-points="countPoints"
            :active-count-points="activeCountPoints"
          />
        </div>
      </v-tabs-item>
    </v-tabs-items>
    <div v-if="tab === 1" class="action-panel">
      <v-btn
        v-if="notComeDateFillFrom && (isManager || isUser)"
        color="primary"
        elevation="0"
        @click="goProgramPageForm"
      >
        Подать заявку
      </v-btn>
    </div>
  </div>
</template>
<script setup>
import store from '@/store'
import { computed, onMounted, ref } from 'vue'
import { api } from '@/api/Api'
import { useRoute, useRouter } from 'vue-router'
import {
  downloadFileWithLinkData,
  getFileIcon,
  getFormatDateDefaultShort,
  getFormatDateTime,
} from '@/helpers'
import ProgramTasksList from '@/views/Programs/ProgramTasksList.vue'
import moment from 'moment'

const route = useRoute()
const router = useRouter()

const files = ref()
const tab = ref(null)
const isLoading = ref(false)
const project = ref(null)
const countPoints = ref(0)
const program = ref()
const activeCountPoints = ref(0)
const briefcase = ref('')

const isManager = computed(() => store.getters['user/isManager'])
const isUser = computed(() => store.getters['user/isUser'])
const notComeDateFillFrom = computed(() =>
  moment(program.value?.dateFillFrom).isValid()
    ? moment(new Date()).isAfter(program.value?.dateFillFrom)
    : true,
)

function goProgramPageForm() {
  return router.push(`/programs/create/${program.value?.code1C}`)
}
async function downloadFile(file) {
  try {
    await api
      .files()
      .downloadFile(file.fileId)
      .then((data) => {
        downloadFileWithLinkData(data, file.name)
      })
  } catch (err) {
    console.log(err)
  }
}
// const getPointsCount = async () => {
//   try {
//     await api
//       .projects()
//       .getPointsCount({ [`filter[templateCode]`]: project.value?.templateCode })
//       .then((data) => {
//         countPoints.value = data?.count
//       })
//   } catch (err) {
//     console.log(err)
//   }
// }

const getActivePointsCount = async () => {
  try {
    await api
      .projects()
      .getActivePointsCount({ [`filter[templateCode]`]: project.value?.templateCode })
      .then((data) => {
        activeCountPoints.value = data?.count
      })
  } catch (err) {
    console.log(err)
  }
}

const handleGoBackClick = () => {
  router.push('/projects')
}

const getBriefcaseProject = async () => {
  try {
    await api
      .projects()
      .getProjectBriefcaseName(project.value?.briefcaseCode)
      .then((data) => {
        briefcase.value = data
      })
  } catch (err) {
    console.log(err)
  }
}

const getProjectById = () => {
  isLoading.value = true
  try {
    api
      .questionnaires()
      .getProjectsById(route.params?.id)
      .then((data) => {
        project.value = data
        files.value = data.files
        getProgramData()
        getBriefcaseProject()
        //return store.commit('app/setPageTitle', data.name)
      })
      .catch((e) => {
        if (e.response.status === 404) {
          return router.push({ name: 'not-found' })
        }

        if (e.response.status === 403) {
          router.push({ name: 'access-denied', params: { code: e.response.status } })
        }
      })
  } finally {
    isLoading.value = false
  }
}

async function getProgramData() {
  isLoading.value = true
  try {
    await api
      .programs()
      .getActiveTemplateById(project.value?.templateCode)
      .then((data) => {
        // getPointsCount()
        getActivePointsCount()
        program.value = data
      })
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getProjectById()
})
</script>
<style lang="scss" scoped>
.page {
  padding: 24px;
  margin-top: 64px;
  flex: 1;
}

.importantInfo {
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--error-theme);
  color: white;
}

.dates {
  display: flex;
  gap: 16px;

  > div {
    flex: 1;
  }
}

.task {
  color: var(--color-neutral-darkest);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.program-content {
  border-top: 1px solid var(--menu-color);
  display: grid;
  grid-template-columns: 1fr 430px;
  align-items: stretch;
  flex: 1;

  > div {
    padding: 24px;

    &:first-child {
      border-right: 1px solid var(--menu-color);
    }
  }

  @media screen and (max-width: 756px) {
    display: flex;
    flex-direction: column-reverse;

    > div {
      padding: 12px 24px 0;
    }
  }
}

.action-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 288px);
  z-index: 9;
  padding: 14px 24px;
  background: white;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--menu-color);

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

.program-head {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 24px;
  gap: 24px;
  position: sticky;
  top: 0;
  z-index: 99;
  padding-top: 66px;

  .title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}

.value-wrapper {
  :last-child {
    margin-bottom: 0;
  }
}

.tabs-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 65px;
  position: relative;
  flex: 1;

  ul,
  ol {
    padding: 0;
  }
}

.sidebar {
  > div {
    margin-bottom: 16px;

    &.task-row {
      margin-bottom: 24px;
    }
  }
}

.files {
  margin-top: 40px;
}
</style>
<style>
.task-wrapper {
  .tasks-page {
    padding-top: 24px;
  }
}

.tabs-container {
  .v-slide-group {
    overflow: visible;
  }
  ul,
  ol {
    padding-left: 20px;
    margin: 10px 0;
  }
}
.text-formatter {
  ol,
  ul {
    padding-left: 30px;
  }
}

.tabs {
  padding: 0 24px;
  .v-btn__content {
    color: var(--text-caption);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .v-slide-group-item--active {
    .v-btn__content {
      color: var(--color-neutral-darkest);

      .v-tab__slider {
        color: var(--primary);
      }
    }
  }

  .v-tab.v-tab {
    border-radius: 0;
  }
}

.tabs-wrapper {
  height: 100%;
  flex: 1;
}

.v-tab.v-tab {
  padding: 8px 10px;
}

.counter {
  &.tab-counter {
    margin: 0 6px;
    background: var(--primary);
    color: white;
    text-align: center;
  }
}
</style>
